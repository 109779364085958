import * as React from "react"
import DocumentationContent from "../../components/DocumentationContent"
import DocumentationSidebar from "../../components/DocumentationSidebar"
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/Layout'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import {FullSlideshowLightboxExample, SlideshowLightboxExampleDoc, SlideshowLightboxExample, 
        PropsDoc,
        ThemesDoc,
        TutorialsList} from "../../components/Examples";
import Content, { HTMLContent } from '../../components/Content'
import Documentation from '../../components/Documentation'
import {SlideshowLightbox, Image} from "lightbox.js-react";
import { CopyBlock, CodeBlock, atomOneDark, solarizedDark, xt256 } from "react-code-blocks";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

export default function SlideshowLightboxComponent () {
  const images = [{title: "Photon", src: "https://source.unsplash.com/600x400/?pastel+cloud"}, 
  {title: "Inversion", src: "https://source.unsplash.com/500x400/?pastel+clouds"},
  {title: "Legion", src: "https://source.unsplash.com/600x400/?pastel+sky"},
  {title: "Inversion", src: "https://source.unsplash.com/600x400/?pastel"},
  {title: "Twenty Neue", src: "https://source.unsplash.com/600x400/?sky+pastel"},
  {title: "Geometrik", src: "https://source.unsplash.com/600x400/?dreamy+clouds"},         
  {title: "Achtung", src: "https://source.unsplash.com/600x400/?pastel+fruit"},];
  const showLineNumbers = true;
  const wrapLines = true;
  const codeBlock = false;

  return <Layout>
  <Header />
  <section className="section section--gradient font-inter bg_white">
  
      <div className="pt-4">
      <main className="flex-grow">

          <section>
          <div className="max-w-6xl mx-auto px-2 sm:px-2">
              <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Main content */}
              <div className="md:flex md:justify-between" data-sticky-container>

                  <DocumentationSidebar />

                  <div className="md:flex-grow documentation font-inter">

                    {/* <Header /> */}
                    <div className="text-lg text-gray-600">
                      <h1 className="h1 text-gray-900 mb-4 font-bold">Slideshow Lightbox</h1>

                      <p className="mb-8">
                      This component features a lightbox containing a slideshow, which allows users to cycle through the images in the gallery.
                      </p>
                      
                      <p className="list_desc">The following features are supported:</p>

                      <ul className="list-disc list ml-8">
                          <li><b>Zooming:</b> Users can zoom through their mouse wheel on desktop devices, or through pinch-to-zoom 
                          on mobile devices.

                          </li>
                          <li> <b>Panning:</b> Once an image is zoomed into, the image can be panned by dragging the image through the mouse, or if on a mobile device, with a swipe-to-drag motion.</li>
                          <li><b>Lots of customization options:</b> There are lots of props available that you can edit to update the theme of the lightbox, as well as 
                          its functionality.</li>

                    </ul>

                    <h3>Demo</h3>

                                <SlideshowLightbox className='container grid grid-cols-3 gap-2 mx-auto mb-8' showThumbnails={true}>
                                    {/* <img className='w-full rounded' src='https://source.unsplash.com/pAKCx4y2H6Q/1400x1200' />
                                    <img className='w-full rounded' src='https://source.unsplash.com/AYS2sSAMyhc/1400x1200' />  
                                    <img className='w-full rounded' src='https://source.unsplash.com/Kk8mEQAoIpI/1400x1200' /> */}

                                    <img className='h-full rounded object-cover' style={{height: "100%"}} src='https://images.pexels.com/photos/580151/pexels-photo-580151.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' />
                                    <img className='h-full rounded object-cover' style={{height: "100%"}}  src='https://images.pexels.com/photos/13996896/pexels-photo-13996896.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' />  
                                    <img className='h-full rounded object-cover' style={{height: "100%"}}  src='https://images.pexels.com/photos/13208323/pexels-photo-13208323.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' />
                                </SlideshowLightbox> 

                    <h3 id="import" className="h3 mt-16 text-gray-900 font-bold text-3xl" style={{ scrollMarginTop: '100px' }}>Import</h3>

                    <p>
                    Firstly, import the components required as well as the CSS file for this library:
                    </p>

                    <div className="mb-8">
                        <SyntaxHighlighter language="jsx" style={oneDark}  className="mb-8 codeBlock">
                        {`import 'lightbox.js-react/dist/index.css'\nimport {SlideshowLightbox} from 'lightbox.js-react'`}
                        </SyntaxHighlighter>
                    </div>

                    <h3 id="usage" className="text-gray-900 mb-4 text-3xl font-semibold" style={{ scrollMarginTop: '100px' }}>Usage</h3>

                    <p>
                    Next, wrap the images you wish to include in the slideshow gallery within a <code>SlideshowLightbox</code> component as shown below:
                    </p>
                    
                    <SlideshowLightboxExample />
                    
                    <p>This will display an image gallery as shown below:</p>
                    <SlideshowLightbox className='container grid grid-cols-3 gap-2 mx-auto mb-8'>
                      <img className='h-full rounded object-cover' style={{height: "100%"}} src='https://images.pexels.com/photos/580151/pexels-photo-580151.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' />
                      <img className='h-full rounded object-cover' style={{height: "100%"}} src='https://images.pexels.com/photos/13996896/pexels-photo-13996896.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' />  
                      <img className='h-full rounded object-cover' style={{height: "100%"}} src='https://images.pexels.com/photos/13208323/pexels-photo-13208323.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' />
                               
                    </SlideshowLightbox> 

                    <SlideshowLightboxExampleDoc className="mt-8" />                    

                    <p className="flex mt-8 items-center text-base px-3 py-4 text-gray-800 bg-blue-100 border border-blue-200 rounded mb-8">
                      <svg className="w-4 h-4 fill-current text-blue-500 flex-shrink-0 mr-2" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm2.629 11.618L8 10.236l-2.629 1.382.5-2.927-2.124-2.073 2.939-.427L8 3.528l1.314 2.663 2.939.427-2.126 2.073.502 2.927z" fill="#338CF5" fillRule="nonzero" />
                      </svg>
                      <span>Check out the <a className="text-blue-600 hover:underline" href="#props">Image</a> component to display a single image.</span>
                    </p>   

                 <h4 className='mt-12 text-xl'>Alternative Method: Array of Images</h4>

                <p>
                  An alternative method of rendering images in the lightbox is by passing an array of images 
                  to the SlideshowLightbox component. To get started, just create an array of images 
                  (as shown in the code sample below) and then pass this array to the <code>images</code>
                  prop.
                </p> 

          <SyntaxHighlighter language="jsx" style={oneDark} className="mb-8 codeBlock">
      {`import React from 'react'
import {SlideshowLightbox} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
          
function Demo() {

  const images = [
    {
      src: 'https://source.unsplash.com/sQZ_A17cufs/549x711',
      alt: 'Mechanical keyboard with white keycaps.',
    },
    {
      src: 'https://source.unsplash.com/rsAeSMzOX9Y/768x512',
      alt: 'Mechanical keyboard with white, pastel green and red keycaps.',
    },
    {
      src: 'https://source.unsplash.com/Z6SXt1v5tP8/768x512',
      alt: 'Mechanical keyboard with white, pastel pink, yellow and red keycaps.',
    },
  ]

  let [isOpen, setIsOpen] = useState(false);  

  return (
    <div>
      <button onClick={() => {setIsOpen(true)}}> 
        Open Lightbox
      </button>
      <SlideshowLightbox 
        images={images} 
        showThumbnails={true} 
        open={isOpen} 
        lightboxIdentifier="lbox1"
        onClose={() =>{setIsOpen(false)}}>     
      </SlideshowLightbox>
    </div>
  )
}`}
        </SyntaxHighlighter>         

                    <ThemesDoc />

                    <h1 className="h1 text-3xl pt-4 mt-4 text-gray-900 mb-4 font-bold">Tutorials</h1>

                    <p className="mb-8">
                      Here are some tutorials for utilizing various features of Lightbox.js, such as 
                      video support, opening/closing the lightbox and so forth:
                    </p>

                    <TutorialsList />

                    <article className="mb-8 mt-8">
                      <h2 className="h2 text-gray-900 mb-4 text-3xl font-semibold">Customization</h2>

                      <h4>Theme</h4>
                      <p className="list_desc">
                        If you'd like to change the theme of the lightbox, this can be done by passing a theme name 
                        to the <code>theme</code> prop, with the options including: "lightbox", "night", "day"
                      </p>

                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox theme="lightbox">
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

                        <h4>Full Screen Images</h4>
                      <p className="list_desc">
                        If you'd like the images to take up the screen's full available width and height, simply set the 
                        <code>fullScreen</code> prop to <code>true</code>. The default is <code>false</code>.
                      </p>

                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox fullScreen={true}>
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

                        <h4>Show Controls</h4>
                      <p>
                        If you'd like to remove the controls (in the top-right corner) of the lightbox, 
                        this can be done by setting the
                        <code>showControls</code> prop to <code>false</code>:
                      </p>

                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox showControls={false}>
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

                        <h4>Lightbox Image Styling</h4>
                      <p>
                      If you'd like to add custom styling to the lightbox's image, 
                      custom classname(s) to be passed via the `lightboxImgClass` prop.
                      </p>

                        <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox lightboxImgClass={"lightboxImg"} 
... 
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

                        <p>
                        You could then select the `img` component within the lightbox with CSS, such as:
                        </p>

                        <SyntaxHighlighter language="css" style={oneDark} className="codeBlock">
                            {`.lightboxImg img {
...
}`}
                            </SyntaxHighlighter>

                      <h4>Background Color</h4>
                      <p>
                        If you'd like to customize the background color of the lightbox, this can be done by passing a color 
                        to the <code>color</code> prop, as a RGB, RGBA, HEX or CSS color name value:
                      </p>

                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox backgroundColor="white">
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>
                      
                     
                      <h4>Icon Color</h4>
                      <p>
                        The icon colors can also be specified through the `iconColor` prop, and the color can be a RGB, 
                        RGBA, HEX or CSS color name value.
                      </p>

                        <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox iconColor="silver">
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

                    <h4>Thumbnail border</h4>
                    <p>
                        Each thumbnail contains a border which can be customized or removed entirely (by setting the border color 
                        to transparent). The border can be customized through specifying a color value for the `thumbnailBorder` prop, 
                        and the color can be a RGB, RGBA, HEX or CSS color name value.
                    </p>

                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox thumbnailBorder="silver">
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                      </div>

                    <h4>Show Thumbnails</h4>
                    <p>
                        If you would like to remove thumbnails entirely from the lightbox, you can do so by setting the 
                        `showThumbnails` prop to `false`.
                    </p>

                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox showThumbnails="false">
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                      </div>

                      <h4>Show/Hide Certain Icons</h4>
                      <p>
                        If you'd like to show/hide particular icons in the controls at the top of the lightbox, 
                        this can be done by toggling the respective values to true/false.
                      </p>

                      <p>The props are:</p>

                      <ul className="list list-disc ml-8">
                          <li>showFullScreenIcon</li>
                          <li>showThumbnailIcon</li> 
                          <li>showSlideshowIcon</li> 
                          <li>showMagnificationIcons</li> 

                        </ul>    


                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox showMagnificationIcons={false} showSlideshowIcon={false}>
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>


                      <h4>Image Animation</h4>
                      <p className="list-desc">
                        The animation type can be specified through the `imgAnimation` prop, and the two values include:
                      </p>

                        <ul className="list list-disc ml-8">
                          <li>imgMotion</li>
                          <li>fade</li> 
                        </ul>

                        <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox imgAnimation="fade">
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

                        <h4>Close Lightbox When Outside of Image Is Clicked</h4>
                      <p>
                        If you'd like to close the lightbox when the outside of the image is clicked, 
                        this can be done by setting the <code>modalClose</code> prop to <code>clickOutside</code>:
                      </p>

                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox modalClose="clickOutside">
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

                        <h4>Open</h4>
                      <p>
                        If you'd like to open/close the lightbox (through a custom element, such as via a button),
                        this can be done by toggling the value of the <code>open</code> prop. 
                        For a full tutorial, take a look at <Link className="text-blue-500 underline" to="/docs/LightboxOpenProp/">this guide here</Link>.
                      </p>

                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox open={isOpen}>
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

                        <h4>Starting slide index</h4>
                      <p>
                       The starting slide index the lightbox should open to when the modal is opened/closed.
                       The default is 0.
                        For a full tutorial, take a look at <Link className="text-blue-500 underline"  to="/docs/LightboxOpenProp/">this guide here</Link>.
                      </p>

                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox startingSlideIndex={0}>
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

                        <h4>Disable zooming</h4>
                      <p>
                        By default, image zooming is enabled. To disable the image zoom 
                        functionality, set the <code>disableImageZoom</code> prop to <code>true</code>:
                      </p>

                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox disableImageZoom={true}>
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>
                        
                        <h4>Slideshow Interval</h4>
                      <p>
                        When the slideshow is playing, the amount of time between slideshow transitions can be specified through the 
                        `slideshowInterval` prop in <b>milliseconds</b>.
                      </p>

   
                        <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox slideshowInterval={1600}>
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

                        <h4>Animate Thumbnails</h4>
                      <p>
                        By default, the thumbnails are animated in and out of the DOM using a fade-in effect. If you would 
                        prefer to switch this animation off, this can be done so by setting the `animateThumbnails` prop 
                        to `false`.
                      </p>

   
                        <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox animateThumbnails={false}>
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>


                        <h4>Rounded Images</h4>
                      <p>
                      Apply rounded effect to the images
                      </p>

   
                        <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox roundedImages={true}>
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

                        <h4>nextArrow</h4>
                      <p>
                      Custom component to be included as the next arrow. If you'd like to render a different arrow 
                      to the default, just pass a custom component to the `nextArrow` prop.
                      </p>

   
                        <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox nextArrow={<CustomArrow />}>
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

                        <h4>prevArrow</h4>
                      <p>
                      Custom component to be included for the previous arrow. If you'd like to render a different arrow 
                      to the default, just pass a custom component to the `prevArrow` prop.
                      </p>
   
                        <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox prevArrow={<CustomArrow />}>
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

                        <h4>rightArrowClassname</h4>
                      <p>
                      Custom classname for the lightbox's right arrow
                      </p>
   
                        <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox rightArrowClassname="rightArrow">
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

                        <h4>leftArrowClassname</h4>
                      <p>
                      Custom classname for the lightbox's left arrow
                      </p>
   
                        <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox leftArrowClassname="leftArrow">
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

                        <h4>captionStyle</h4>
                      <p>
                      Style object passed to the image caption element
                      </p>
   
                        <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox captionStyle={{color: "silver"}}>
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>


      </article>

      <PropsDoc />
    
    </div>

    {/* Related content */}
    <div className="mt-8">
      <h3 className="h3 mb-8 font-semibold">Related</h3>
      <Link className="flex justify-between items-center p-4 rounded border border-gray-200 
      transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" to="/docs/Image">
        <div>
          <div className="text-normal font-medium mb-1">Image component</div>
          <div className="text-sm text-gray-600">If you'd prefer to display a single image within a lightbox, be sure 
          to check out the Image component.</div>
        </div>
        <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
        </svg>
      </Link>
      <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition 
      duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="https://github.com/silvia-odwyer/lightbox.js">
        <div>
          <div className="text-normal font-medium mb-1">Feature request</div>
          <div className="text-sm text-gray-600">Want to request a new feature? Be sure to open an issue in our GitHub repository!</div>
        </div>
        <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
        </svg>
      </a>
      <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
    </div>

    {/* <Feedback /> */}

  </div>

              </div>

              </div>
          </div>
          </section>

          </main>
      </div>
          
  </section>
    <Footer overrideDarkTheme={true} />
  </Layout>
  
}